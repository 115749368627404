import "../static/css/styles.css";
// import GooglePlayBadge from "../static/img/google-play-badge.svg";
import AppStoreBadge from "../static/img/app-store-badge.svg";
// import DemoScreen from "../static/img/demo-screen.mp4";
import DemoPage from "../static/img/demo.png";

export default function HomePage() {
  return (
    <div className="text-center">
      <header className="masthead">
        <div className="container px-5">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6">
              <div className="mb-5 mb-lg-0 text-center text-lg-start">
                <h1 className="display-1 lh-1 mb-3 text-center">PartyBusters</h1>
                <p className="lead fw-normal text-muted mb-5 text-center">
                  Connect, team up, and dominate in SquadBusters!
                  <br />
                  Download PartyBusters today and join the fun!
                </p>
                <div className="d-flex flex-column flex-lg-row align-items-center justify-content-center">
                  {/* Uncomment when ready for Play Store release */}
                  {/* <a
                    className="me-lg-3 mb-4 mb-lg-0"
                    href="#!"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="app-badge"
                      src={GooglePlayBadge}
                      alt="..."
                    />
                  </a> */}
                  <a
                    href="https://apps.apple.com/us/app/party-busters/id6503458517"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img className="app-badge" src={AppStoreBadge} alt="..." />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              {/* Masthead device mockup feature */}
              <div className="masthead-device-mockup">
                <svg
                  className="circle"
                  viewBox="0 0 100 100"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <defs>
                    <linearGradient
                      id="circleGradient"
                      gradientTransform="rotate(45)"
                    >
                      <stop className="gradient-start-color" offset="0%"></stop>
                      <stop className="gradient-end-color" offset="100%"></stop>
                    </linearGradient>
                  </defs>
                  <circle cx="50" cy="50" r="50"></circle>
                </svg>
                <svg
                  className="shape-1 d-none d-sm-block"
                  viewBox="0 0 240.83 240.83"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="-32.54"
                    y="78.39"
                    width="305.92"
                    height="84.05"
                    rx="42.03"
                    transform="translate(120.42 -49.88) rotate(45)"
                  ></rect>
                  <rect
                    x="-32.54"
                    y="78.39"
                    width="305.92"
                    height="84.05"
                    rx="42.03"
                    transform="translate(-49.88 120.42) rotate(-45)"
                  ></rect>
                </svg>
                <svg
                  className="shape-2 d-none d-sm-block"
                  viewBox="0 0 100 100"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="50" cy="50" r="50"></circle>
                </svg>
                <div className="device-wrapper">
                  <div
                    className="device"
                    data-device="iPhoneX"
                    data-orientation="portrait"
                    data-color="black"
                  >
                    <div className="screen bg-black">
                      <img
                        style={{ maxWidth: "100%", height: "100%" }}
                        src={DemoPage}
                        alt="PartyBusters Preview"
                      />
                      {/* <video
                        muted="muted"
                        autoPlay
                        loop
                        style={{ maxWidth: "100%", height: "100%" }}
                        >
                        <source src={DemoScreen} type="video/mp4" />
                      </video> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}
