export default function Privacy() {
    return (
      <div className="container mt-5">
        <h1>Privacy Policy for Party Busters</h1>
        <p>
          <strong>Effective Date: 11/24/24</strong>
        </p>
  
        <h2>1. Introduction</h2>
        <p>
          Welcome to <strong>Party Busters</strong>. We respect your privacy and are
          committed to protecting your personal data. This Privacy Policy will
          inform you how we handle your personal data when you use our app and
          tell you about your privacy rights and how the law protects you.
        </p>
  
        <h2>2. Data We Collect</h2>
        <p>
          We collect and use the following information to provide, improve, and
          protect our services:
        </p>
        <ul>
          <li>
            <strong>Device Information</strong>: We collect device-specific
            information (such as your hardware model, operating system version,
            unique device identifiers, and mobile network information).
          </li>
          <li>
            <strong>Analytics Data</strong>: We collect information about your
            interactions with the app (such as how often you use the app, app
            performance data, and error rates). This information is aggregated and
            not linked to any specific personal data.
          </li>
        </ul>
  
        <h2>3. Use of Data</h2>
        <p>We use the data we collect for the following purposes:</p>
        <ul>
          <li>
            <strong>To Provide and Maintain our Service</strong>: Including to
            monitor the usage of our service.
          </li>
          <li>
            <strong>To Display Ads</strong>: Our app displays ads, and we use
            information collected to make the ads relevant to you. We do not use
            personally identifiable information to display ads.
          </li>
          <li>
            <strong>To Improve our Service</strong>: We analyze usage data to
            understand how our app is used and to improve the user experience.
          </li>
        </ul>
  
        <h2>4. Data Sharing</h2>
        <p>
          We may share your data with third parties in the following situations:
        </p>
        <ul>
          <li>
            <strong>With Ad Networks</strong>: For the purpose of displaying ads.
            We ensure that the ad networks only have access to non-personally
            identifiable information.
          </li>
          <li>
            <strong>For Legal Reasons</strong>: We may disclose your information
            if required to do so by law or in response to valid requests by public
            authorities.
          </li>
        </ul>
  
        <h2>5. Security of Data</h2>
        <p>
          The security of your data is important to us but remember that no method
          of transmission over the Internet or method of electronic storage is
          100% secure. While we strive to use commercially acceptable means to
          protect your Personal Data, we cannot guarantee its absolute security.
        </p>
  
        <h2>6. Data Retention</h2>
        <p>
          <strong>How long we keep data:</strong> We retain personal data only for
          as long as necessary to provide the services you have requested and
          thereafter for a variety of legitimate legal or business purposes. These
          might include retention periods mandated by law, contract, or similar
          obligations applicable to our business operations; for preserving,
          resolving, defending, or enforcing our legal/contractual rights; or
          needed to maintain adequate and accurate business and financial records.
        </p>
  
        <h2>7. Your Privacy Rights</h2>
        <p>
          <strong>Access and Control:</strong> You have the right to access,
          update, or delete the information we have on you. Each request can be
          directed to the contact information provided below.
        </p>
        <p>
          <strong>Consent:</strong> You may withdraw your consent at any time by
          contacting us. Doing so may affect your enjoyment of certain features of
          our services.
        </p>
  
        <h2>8. Third-party Services</h2>
        <p>
          <strong>Service Providers:</strong> We may employ third-party companies
          and individuals to facilitate our Service ("Service Providers"), to
          provide the Service on our behalf, to perform service-related services
          or to assist us in analyzing how our Service is used.
        </p>
        <p>
          <strong>Third-party links:</strong> Our Service may contain links to
          third-party web sites or services that are not owned or controlled by
          us. We have no control over, and assume no responsibility for the
          content, privacy policies, or practices of any third party web sites or
          services.
        </p>
  
        <h2>9. Children’s Privacy</h2>
        <p>
          <strong>Age Limitations:</strong> The services are not intended for use
          by children under the age of 13 without parental consent. We do not
          knowingly collect personally identifiable information from children
          under 13.
        </p>
        <p>
          <strong>Parental Control:</strong> Parents who believe that we may have
          collected personal data from a child under the age of 13 without their
          consent can submit a request that it be removed to our contact address
          below.
        </p>
  
        <h2>10. Changes to This Privacy Policy</h2>
        <p>
          Updates: Inform users that the policy may be updated and how they will
          be notified of these changes.
        </p>
  
        <h2>11. Contact Us</h2>
        <p>
          Communication: Provide contact details for privacy concerns or queries.
        </p>
      </div>
    );
  }
  